body {
	margin: 0;
	padding: 0;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	background: #ebebeb;
}

header.AppBar {
	background: #ebebeb;
}

img.MainLogo {
	max-width: 200px
}

nav.MainNav {
	-webkit-flex-grow: 1;
	   -moz-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	text-align: right;
	overflow: visible;
}

nav.MainNav button {
	overflow: visible;
}

div.SplashBanner {
	background-image: url('/img/header_art.jpg');
	background-size: cover;
	min-height: 200px;
}

div.BannerText {
	background: url('/img/header_text.png');
	background-size: contain;
	background-repeat: no-repeat;
	min-height: 200px;
}

div.SalesPitch {
	padding: 0 1rem 0 1rem;
}

div.BottomSales {
	padding: 0 1rem;
	margin-bottom: 3rem;
}

div.BottomSales button {
	margin-bottom: 2rem;
}

img.BottomSalesImg {
	width: 80%;
	max-width: 280px;
	margin-bottom: 2rem;
	text-align: right;
	margin-right: 10%;
}

div.BottomSales h5.callout {
	line-height: 150%;
	margin: 1rem 0 0 0;
}

img.Truck {
	max-width: 90%;
}

img.Splat {
	width: 90%;
	margin-right: 10%
}

img.PreviewPixels {
	margin-top: -20%;
}

div.PreviewPixels div:nth-of-type(1) .PreviewCard {
	-webkit-transform: rotate(-20deg);
	   -moz-transform: rotate(-20deg);
	    -ms-transform: rotate(-20deg);
	     -o-transform: rotate(-20deg);
	        transform: rotate(-20deg)
}

div.PreviewPixels div:last-of-type .PreviewCard {
	-webkit-transform: rotate(20deg);
	   -moz-transform: rotate(20deg);
	    -ms-transform: rotate(20deg);
	     -o-transform: rotate(20deg);
	        transform: rotate(20deg)
}

ul.SalesList {
	margin: 1rem 0 1rem 1.5rem;
	list-style-type: disc;
}

ul.SalesList li {
	display: list-item;
	padding-top: 5px;
	padding-bottom: 5px;
}

ul.SalesList li span {
	line-height: 2em;
	font-size: .9rem !important;
}

div.BottomSales h5 {
	color: #C12033;
	text-transform: uppercase;
}

h1.callout, h5.callout, h2.callout {
	margin-top: 2rem
}

div.PixelPicker {
	margin: 3rem 0;
	border-top: 1px solid rgba(0,0,0,.1);
	border-bottom: 1px solid rgba(0,0,0,.1);
	box-shadow: inset 0 0 3px 0 rgba(0,0,0,.25);
	background-color: rgba(0,0,0,.1);
	padding: 0 2rem 1rem 2rem;
}

h1.CurrentCategory {
	color: #C12033;
	display: inline-block;
	text-align: center;
    background-color: #fafafa;
    padding: 1rem;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	margin: 1.5rem 0 2rem 0;
	text-transform: uppercase;
}

h1.CurrentCategory .BigIcon {
	vertical-align: text-bottom;
}

h5.TapToAdd {
	margin: 1.5rem 0 0 0;
	color: #666;
	text-shadow: 1px 1px rgba(255,255,255,.5);
}

footer {
	padding: 2rem 1rem;
	background-color: rgba(0,0,0,.8);
	box-shadow: inset 0 0 5px 0 rgba(0,0,0,.75);
	color: white;
}

footer.Footer a, footer.Footer p {
	color: white;
}

footer img {margin-bottom: 2rem}

div.ShoppingCartContain {
	padding: 1rem 0;
}

/* GridList component */
ul.ShoppingCartItems {
	margin: 0;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-flow: row wrap;
	   -moz-box-orient: horizontal;
	   -moz-box-direction: normal;
	    -ms-flex-flow: row wrap;
	        flex-flow: row wrap;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	overflow: visible;
}

/* GridListItem component */
li.CartItemRoot {
	width: 50%;
	-webkit-flex: 0 1 auto;
	   -moz-box-flex: 0;
	    -ms-flex: 0 1 auto;
	        flex: 0 1 auto;
	margin: 0;
	overflow: visible;
}

div.CartItemTile {
	overflow: visible;
	margin: 8px;
}

div.CheckoutValues {
	margin: 1.5rem 0;
}

.CartHeading {
	padding-bottom: 1rem;
	color: black;
	font-weight: 500;
	text-transform: uppercase;
	font-size: .9rem;
}

span.MoneyHolder {
	display: inline-block;
	min-width: 80px;
}

span.NegativeMoney {
	color: green;
}

div.CartCard {
	padding: .5rem;
	position: relative;
	width: 100%;
}

div.CartCard img {
	margin-bottom: .5rem
}

div.CartCard .PixelQuant {
	float: right;
	font-weight: bold;
}

div.CartCard button {
	min-width: 0;
	padding: 2px;
}

div.DrawerPaper {
	width: 85%;
	max-width: 500px;
}

.CartTitle {
	padding-bottom: 1.5rem;
}

p.CartText {
	margin: 1rem 0
}

.BigIcon {
	margin-right: 10px;
	vertical-align: middle;
}


.StripeElement {
	background-color: white;
	padding: 6px 0 9px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
	font-size: 14px;
	line-height: 1.1875em;
	box-shadow: none;
}

.StripeElement--focus {
	border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}

.StripeElement--invalid {
	border-bottom: 1px solid #f44336;
}

label + div.AddressRoot {
	margin: 0;
}

.NoMargin {
	margin: 0 !important;
}

input.AddressElement, select.AddressElement {
	font-size: .875rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

input.AddressElement:focus, select.AddressElement:focus {
	border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}

input.AddressElement[aria-invalid='true'], select.AddressElement[aria-invalid='true'] {
	border-bottom: 1px solid #f44336;
}

input.AddressElement::-webkit-input-placeholder, select.AddressElement::-webkit-input-placeholder {
	opacity: 1;
	color: rgba(0,0,0,.4);
}

input.AddressElement::-moz-placeholder, select.AddressElement::-moz-placeholder {
	opacity: 1;
	color: rgba(0,0,0,.4);
}

input.AddressElement:-ms-input-placeholder, select.AddressElement:-ms-input-placeholder {
	opacity: 1;
	color: rgba(0,0,0,.4);
}

input.AddressElement::-ms-input-placeholder, select.AddressElement::-ms-input-placeholder {
	opacity: 1;
	color: rgba(0,0,0,.4);
}

input.AddressElement::placeholder, select.AddressElement::placeholder {
	opacity: 1;
	color: rgba(0,0,0,.4);
}

p.CheckoutHelpText {
	line-height: 150%;
}

p.CheckoutHelpText:empty {
	display: none
}

label.AddressLabel {
	margin-bottom: 5px;
	position: relative;
	font-size: .875rem;
	color: rgba(0, 0, 0, 0.87);
	font-weight: 500;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	line-height: 1;
	padding: 0;
	-webkit-transform: none;
	   -moz-transform: none;
	    -ms-transform: none;
	     -o-transform: none;
	        transform: none;
	display: inline-block;
}

.buttonSuccess {
	background-color: green
}

.buttonSuccess:hover {
	background-color: darkgreen
}

.buttonProgress {
	color: green;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
}

.checkoutSuccess {
	margin: 1.5rem 0;
}

p.CartOffer {
	text-align: center;
	background-color: #aaa;
	color: white;
	padding: .75rem 1rem;
	margin: 1rem 0;
	box-shadow: 0 0 1px 0 rgba(0,0,0,.1);
	position: relative;
	border-radius: 4px;
	box-shadow: inset 0 -1px 3px 0 rgba(0,0,0,.2);
}

p.CartOffer[offercomplete="1"] {
	background-color: #03a9f4;
}

.PromoComplete {
	visibility: hidden;
	position: absolute;
	right: .6rem;
	top: .6rem;
	color: white;
}

p.CartOffer[offercomplete="1"] .PromoComplete {
	visibility: visible;
}

button.ActionButton {
	background-color: #C12033;
	color: white;
	border-radius: 0;
	margin-bottom: 1rem;
}

button.ActionButton:hover {
	background-color: #9f1a2a;
}

p.Contacts {
	line-height: 50px;
	margin-bottom: 2rem;
}

div.ForSale {
	margin-bottom: 0;
}

div.PixelCard {
	cursor: pointer;
	position: relative;
	overflow: visible;
	width: 100%;
}

div.pixelSelected {
	position: absolute;
	top: 0;
	left: 0;
	color: white;
	background: #03a9f4;
	text-align: center;
	padding: 0 15px;
	line-height: 40px;
	min-width: 40px;
	font-weight: bold;
	box-shadow: inset 0 0 1px 0 rgba(0,0,0,.25);
}

div.wrapper {
	position: relative;
}

ul.CategoryListRoot {
	margin: 2.5rem 0 2rem 0 !important;
	-webkit-justify-content: center;
	   -moz-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

li.CategoryItemRoot {
	display: inline-block;
	text-align: center;
	padding: 0;
	margin: 0 0 1rem 0;
	font-size: 1rem;
	color: #0398db;
	text-transform: uppercase;
	cursor: pointer;
	
}

div.CategoryItemTile {
	margin: 0 .5rem;
	padding: 0;
	background-color: rgba(255,255,255,.5);
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 3px 1px -2px rgba(0, 0, 0, 0.06);
}

li.CategoryItemRoot[current='true'] div.CategoryItemTile {
	color: white;
	background-color: #03a9f4;
	box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
	text-shadow: 1px 1px rgba(0,0,0,.5);
}

li.CategoryItemRoot a {
	padding: 1rem 1.25rem;
	margin: 0;
	display: block;
}

div.footerCats {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	margin-bottom: 3rem;
	padding-top: .5rem;
	padding-bottom: 1.5rem;
}

footer a {
	cursor: pointer;
}

div.coverCard {
	position: absolute;
	height: 100%; 
	width: 100%;
	z-index: 10;
}

div.holdCover {
	position: relative;
	width: 100%;
}

img.cloned_img {
	position: fixed;
	z-index: 10000;
	max-height: 100%;
	max-width: 100%;
	-webkit-transition-property: max-height, max-width, top, right, -webkit-transform;
	transition-property: max-height, max-width, top, right, -webkit-transform;
	-o-transition-property: max-height, max-width, top, right, -o-transform;
	-moz-transition-property: max-height, max-width, top, right, transform, -moz-transform;
	transition-property: max-height, max-width, top, right, transform;
	transition-property: max-height, max-width, top, right, transform, -webkit-transform, -moz-transform, -o-transform;
	-webkit-transition-duration: 1s;
	   -moz-transition-duration: 1s;
	     -o-transition-duration: 1s;
	        transition-duration: 1s;
	-webkit-transition-timing-function: linear;
	   -moz-transition-timing-function: linear;
	     -o-transition-timing-function: linear;
	        transition-timing-function: linear;
}

img.cloned_img_in_cart {
	max-height: 0 !important;
	max-width: 0 !important;
	top: 20px !important;
	right: 50px !important;
	-webkit-transform: rotate(360deg);
	   -moz-transform: rotate(360deg);
	    -ms-transform: rotate(360deg);
	     -o-transform: rotate(360deg);
	        transform: rotate(360deg);
}
